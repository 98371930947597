import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import picIcon from '../assets/icons/pictures_icon.png'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    ${props=>props.minimize?'width: 50vw;':'width: 100%;'}
    ${props=>props.minimize?'height: 65vh;':'height: 100%;'}
    background: #D9D9D9;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;

    Pointer
`

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 15px;
    justify-content: end;
    align-items: center;
    pointer-events: all;
    
    span.header{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        pointer-events: none;
        color: whitesmoke;
        img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
        }
    }

    button{
        margin: auto 10px auto 1px;
        background: #D9D9D9;
        border: 1px solid black;
        padding: 5px;
        width: 25px;
        height: 25px;
        pointer-events: all;
        cursor: pointer; 
        img{
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width:700px){
       span.header{
        font-size: x-small;
       }
    }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    background-color: #D9D9D9;
    align-items: center;
    
`
const Divider = styled.div`
    position: relative;
    width: 3px;
    height: 90%;
    background-color: #A9A9A9;
    margin: auto 5px;
`

const LinkItem = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-left: 10px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: gray;
    img{
        height: 15px;
        margin-right: 5px;
    }
    `

const LogoDiv = styled.div`
    position: relative;
    margin: auto 0 auto auto;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    img{
        height: 70%;
    }

`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 2px;
    border: 1px solid black;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 10px;
    pointer-events: all;
    img{
        object-fit: cover;
    }
`

const ContentFooter = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    div{
        height: 100%;
        width: 60%;
        border: 1px solid black;
        display: flex;
        align-items: center;
        padding-left: 5px;
        &:nth-child(2){
            width: 39%;
            margin: 0 0 0 auto;
            img{
                height: 80%;
                margin: auto 5px;
            }
        }
    }
    @media screen and (max-width:700px){
        div{
            width: 40%;
            &:nth-child(2){
                width: 60%;
            }
        }
        span{
         font-size: x-small;
        }
     }
`


const PortfolioExplorer = ({ id, pushZStack, deactivateTask, showImage, appendToMinimize }) => {

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    const [minimize,toggleMinimize] = useState(true)

    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }

     //offset works
     const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])


    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={picIcon} alt="pc icon" /> {showImage?showImage.title:"Error: no image"} </span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem>Home</LinkItem>
                <LinkItem>Open</LinkItem>
                <LinkItem>File</LinkItem>
                <LinkItem>Settings</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>
            <Content>
                    <img src={showImage?showImage.image:""} alt="logo"/>
            </Content>
            <ContentFooter>
                <div><span>1 Object(s)</span></div>
                <div><img src={picIcon} alt="icon"/><span>{showImage?showImage.title:"Error: no image"}</span> </div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default PortfolioExplorer