import React, {useState,useEffect} from "react"

import Taskbar from "../components/taskbar"
import Loading from "../components/loadingScreen"
import GettingStarted from "../components/gettingStarted"
import Background from "../components/background"
import "../styles/root.css"
import styled from "styled-components"
import DesktopIcons from "../components/desktopIcons"
import FileExplorer from "../components/fileExplorer"
import Portfolio from "../components/portfolio"
import Resume from "../components/resume"
import BashShell from "../components/bashShell"
import ImageViewer from "../components/imageViewer"
import RecycleBin from "../components/recycleBin"
import Gallery from "../components/gallery"
import Contacts from "../components/contacts"

import startIcon from '../assets/logos/logo_plain.png'
import internetIcon from '../assets/icons/internet_icon.png'
import internetPDFIcon from '../assets/icons/pdf_explorer_icon.png'
import recycleIcon from '../assets/icons/trashcan_icon.png'

// Apps
import PcIcon from '../assets/icons/pc_icon.png'
import PromptIcon from '../assets/icons/prompt_icon.png'
// import DocumentIcon from '../assets/icons/documents_icon.png'
import PicIcon from '../assets/icons/pictures_icon.png'
import EmailIcon from '../assets/icons/email_icon.png'

const App = styled.main`
position: relative;
width: 100vw;
height: 100vh;
overflow: hidden;
`

const IndexPage = () => {
  //Global State on Light or Dark mode, true and false respectively
  const [backStack,setBackStack] = useState({})
  const [backTarget,backPress] = useState()
  const [mode,setMode] = useState(true)
  const [zStack,setZStack] = useState([])
  const [aStack,setAStack] = useState([1])
  const [minimizeStack, setMinimizeStack] = useState([])

  // Ok now we need to handle the zIndex, we need the windows to stay in sync. Once a screen is clicked on we add it tothe top of a stack.
  // We can either implement this as a state array on its own, or we can have these spread out in the task Stack
  
  //Lets have state to store latest value
  const [focusIndex,pushZStack] = useState()

  // Used to set active image in image viewer
  const [showImage,setShowImage] = useState({image:'',title:''})
  
  // Lets work with the stack as that would be straight forward to implement
  useEffect(()=>{
    let arr=[]
    if(zStack){
      zStack.map(tar=>{
        if(tar && tar!==focusIndex){
          arr.push(parseInt(tar))
        }
        return ''
      })
      arr.push(parseInt(focusIndex))
      setZStack(arr)
    }else{
      setZStack(parseInt(focusIndex))
    }

    setMinimizeStack(current=>{
      let arr = current.filter(a=>a!=focusIndex)
      return [...arr]}
    )

  }, [focusIndex])
  
  //How will we manage the tasks in the app? Best practice would be to add or remove the components rather than add a class to a component e.g inactive

  // Add component to app / open a window
  const activateTask = (id,init_id) => {
    // console.log('activate '+id)
    setAStack(current=>{
      let arr = current.filter(a=>a!=id)
      return [...arr,id]}
    )
    pushZStack(id)
    setBackStack(curr=>({...curr, [id]:init_id}))
  }  

  // Remove component from app / close a window
  const deactivateTask = (id,init) => {
    // console.log('deactivate '+id)
    setAStack(current=>{
      let arr = current.filter(a=>a!=id)
      return [...arr]}
    )
  }

  const setActiveImage = (data) => {
    setShowImage(data)
    let zArr
    let arr = []
    taskStack.map(task=>{
      if(task?.id!=7){
        arr.push({...task})
      }
      else{
        zArr={ id: 7, title: 'Image Viewer', icon: {PicIcon}, app: <ImageViewer key="7" id="7" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} showImage={data} /> }
      }
      return ''
    })
    arr.push(zArr)
    // console.log(arr)
    setTaskStack(arr)
  }

  useEffect(()=>{
    activateTask(backStack[backTarget],'')
    deactivateTask(backTarget)
    backPress()
  },[backTarget])

  const appendToMinimize = (id)=>{
    setMinimizeStack((current=>{
      let arr = current.filter(a=>a!=id)
      return [...arr, parseInt(id)]}
    ))
  }

  //Ok, so now I need to find a way to centrally manage the tasks, lets add all our tasks onto state
  const [taskStack,setTaskStack] = useState([
    { id: 0, title: 'My Portfolio', icon: {internetIcon}, app: <Portfolio key="0" id="0" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} backPress={backPress} appendToMinimize={appendToMinimize}/> }, // Portfolio
    { id: 1, title: 'Getting started with B4RTO', icon: {startIcon}, app: <GettingStarted  key="1"id='1' activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} appendToMinimize={appendToMinimize}/> }, // Getting Started
    { id: 2, title: 'My Computer', icon: {PcIcon}, app: <FileExplorer key="2" id='2' activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} appendToMinimize={appendToMinimize}/> }, // File Explorer
    { id: 3, title: 'Command Prompt', icon: {PromptIcon}, app: <BashShell key="3" id='3' activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} appendToMinimize={appendToMinimize}/> }, // Bash Explorer
    { id: 4, title: 'My Resume', icon: {internetPDFIcon}, app: <Resume key="4" id="4" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} backPress={backPress} appendToMinimize={appendToMinimize}/> }, // Resume
    { id: 5, title: 'Recycle Bin', icon: {recycleIcon}, app: <RecycleBin key="5" id="5" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} setActiveImage={setActiveImage} appendToMinimize={appendToMinimize}/> }, // Recycle Bin
    { id: 6, title: 'Gallery', icon: {PcIcon}, app: <Gallery key="6" id="6" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} showImage={showImage} setActiveImage={setActiveImage} appendToMinimize={appendToMinimize}/> }, // Recycle Bin
    { id: 7, title: 'Image Viewer', icon: {PicIcon}, app: <ImageViewer key="7" id="7" activateTask={activateTask} deactivateTask={deactivateTask} pushZStack={pushZStack} showImage={showImage} appendToMinimize={appendToMinimize}/> }, // Image viewer
    { id: 8, title: 'Lets Talk!', icon: {EmailIcon}, app: <Contacts key="8" id="8" activateTask={activateTask} deactivateTask={deactivateTask}  pushZStack={pushZStack} backPress={backPress} appendToMinimize={appendToMinimize}/> }, // Email
  ])

  // Log out values of task stack for development purposes 
  useEffect(()=>{
    let zArr
    let arr = []
    taskStack.map(task=>{
      if(task?.id!=focusIndex){
        arr.push({...task})
      }
      else{
        zArr={...task}
      }
      return '' 
    })
    arr.push(zArr)
    setTaskStack(arr)
  }, [focusIndex])


  const [isLoading,setLoading] = useState(true)
  
  // Re-look this section
  useEffect(()=>{

      setTimeout(function(){
        if(document.readyState==='complete'){
            setLoading(false)
        }  
      },2000)
  },[])


useEffect(()=>{
  let url = window.location.href;
  let param =url.split('?')[1]
  if(param==='projects'){
    activateTask(0)
  }
  if(param==='resume'){
    activateTask(4)
  }

},[])

  return (
    <App id="mainApp">
      {isLoading?<Loading setLoading={setLoading}/>:""}
  
      {/* Background */}
      <Background mode={mode} setMode={setMode}/>
      <Taskbar mode={mode} setMode={setMode} activateTask={activateTask} deactivateTask={deactivateTask} taskStack={taskStack} aStack={aStack} pushZStack={pushZStack} />
      <DesktopIcons activateTask={activateTask}/>
      {/* Tasks */}
      {taskStack && taskStack.map(task=>{
        // if(task?.active===true)
        if(task && aStack.includes(task.id) && !minimizeStack.includes(task.id))
          return task.app
        return ""
      })}
    </App>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>Franklin Barto - Software Engineer | Solutions Architect | Data Engineer</title>
    <meta name="keywords" content="Franklin Barto, software developer, data engineer, solutions architect, web developer, software engineer, software portfolio, product design, software development expertise, JavaScript, SQL, Python, cloud computing"/>
    <meta name="robots" content="index, follow"/>
    <link rel="canonical" href="https://www.franklinbarto.com"/>
    {/* <link rel="icon" sizes="32x32" href="/favicon-32x32.png" type="image/x-icon"></link>
    <link rel="icon" sizes="16x16" href="/favicon-16x16.png" type="image/x-icon"></link>
    <link rel="icon" sizes="16x16" href="/favicon-16x16.png" type="image/x-icon"></link>
  <link rel="icon" href="/favicon.ico" /> */}
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <meta name="author" content="Franklin Barto"/>
    <meta name="description" content="A software developer with a knack for turning ideas into reality. With a diverse skill set spanning full-stack development, data engineering, cloud computing, I thrive on building innovative and efficient software solutions that make a real impact."/>
    
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@FranklinKBarto" />
    <meta property="og:url" content="https://twitter.com/FranklinKBarto" />
    <meta property="og:title" content="Franklin Barto | Twitter" />
    <meta property="og:description" content="Follow Franklin Barto on Twitter for Tech related updates and insights." />
    <meta property="og:image" content="https://franklinbarto.com/site-image.jpg" />

    <meta name="linkedin:card" content="summary" />
    <meta name="linkedin:site" content="Franklin Barto" /> 
    <meta property="og:url" content="https://www.linkedin.com/in/frankbarto" />
    <meta property="og:title" content="Franklin Barto | LinkedIn" />
    <meta property="og:description" content="Connect with Franklin Barto on LinkedIn for professional networking." />
    <meta property="og:image" content="https://franklinbarto.com/site-image.jpg" />
  </>
  )  
