import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'

import recycleIcon from '../assets/icons/trashcan_icon.png'

//Gallery images
import img1 from '../assets/recycleBin/dog1.jpg'
import img2 from '../assets/recycleBin/dog2.jpg'
import img3 from '../assets/recycleBin/dog3.jpg'
import img4 from '../assets/recycleBin/dog4.jpg'

const galleryData = 
    [
        {image:img1, title:"Business Idea 1.jpg"},
        {image:img2, title:"Business Idea 2.jpg"},
        {image:img3, title:"Business Idea 3.jpg"},
        {image:img4, title:"Business Idea 4.jpg"}
    ]

    const ContainerDiv = styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        ${props=>props.minimize?'width: 80vw;':'width: 100%;'}
        ${props=>props.minimize?'height: 85vh;':'height: 100%;'}
        background: #D9D9D9;
        z-index: 100;
        border: 1px solid black;
        padding: 5px;
    `

    const Header = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
        padding: 5px 0 5px 15px;
        justify-content: end;
        align-items: center;
        pointer-events: all;
        
        span.header{
            pointer-events: none;
            color: black;
            font-size: 1.2rem;
            margin: 0 auto 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            pointer-events: none;
            color: whitesmoke;
            img{
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }

        button{
            margin: auto 10px auto 1px;
            background: #D9D9D9;
            border: 1px solid black;
            padding: 5px;
            width: 25px;
            height: 25px;
            pointer-events: all;
            cursor: pointer; 
            img{
                width: 100%;
                height: 100%;
            }
        }
    `

    const SubHeader = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        border: 1px solid black;
        background-color: #D9D9D9;
        align-items: center;
        span.subHeader{
        }
    `
    const Divider = styled.div`
        position: relative;
        width: 3px;
        height: 90%;
        background-color: #A9A9A9;
        margin: auto 5px;
    `

    const LinkItem = styled.button`
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        color: black;
        img{
            height: 15px;
            margin-right: 5px;
        }
        `
    const LogoDiv = styled.div`
        position: relative;
        margin: auto 0 auto auto;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100px;
        img{
            height: 70%;
        }
    
    `
    const Content = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: white;
        margin-top: 2px;
        border: 1px solid black;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 10px;
        pointer-events: all;
    `

    const ContentFooter = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        div{
            height: 100%;
            width: 60%;
            border: 1px solid black;
            display: flex;
            align-items: center;
            padding-left: 5px;
            &:nth-child(2){
                width: 39%;
                margin: 0 0 0 auto;
                img{
                    height: 80%;
                    margin: auto 5px;
                }
            }
        }
    `

    const TrashItemStyle = styled.button`
        position: relative;
        margin: 5px auto;
        width: 31%;
        height: 30vh;
        background: none;
        border: none;
        display: flex;
        flex-direction: column;

        img{
            width: 100%;
            height: 80%;
            object-fit: cover;
        }
        span{
            margin-left: 5px;
        }

    `

const RecycleBin = ({ activateTask, deactivateTask, id, pushZStack, setActiveImage, appendToMinimize  }) => {

    const TrashItem = (props)=>{
        return(
            <TrashItemStyle onPointerDown={()=>{setActiveImage({image: props.img, title: props.title});activateTask(7, id);}}>
                <img src={props.img} alt={props.title}/>
                <span>{props.title}</span>
            </TrashItemStyle>
        )
    }

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    const [minimize,toggleMinimize] = useState(true)

    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x = -(mainWindow.getBoundingClientRect().x - e.clientX)
        var y = -(mainWindow.getBoundingClientRect().y - e.clientY)
        updateOffset({x,y})
    }


    const closeWindow = ()=>{
        deactivateTask(id)
    }

    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }

    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX = e.clientX - offset.x
        var newPosY = e.clientY - offset.y
        
        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }else{
            setWindow({x: mainWindow.getBoundingClientRect().x, y: mainWindow.getBoundingClientRect().y})
        }
    }

    useEffect(()=>{
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> window.removeEventListener('mousemove',handleWindowMove)
    },[moveWindow,offset])


    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={recycleIcon} alt="pc icon" /> Recycle Bin </span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem onClick={closeWindow}> <img src={backIcon} alt="back icon"/> back</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>
            <Content>
                {galleryData&&galleryData.map((item)=><TrashItem img={item.image} title={item.title}/>)}
            </Content>
            <ContentFooter>
                <div><span>8 Object(s)</span></div>
                <div><img src={recycleIcon} alt="icon"/> Recycle Bin</div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default RecycleBin
