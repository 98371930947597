import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import closeIcon from '../assets/icons/close_icon.png'
import logo from '../assets/logos/logo_horizontal.png'
import frank from '../assets/artboard/frank_2.png'

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 70vh;
    background: #fff;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;
    pointer-events: all;

    @media screen and (max-width: 900px) {
        width: 75vw;
        height: 70vh;
    }

    @media screen and (max-width: 700px) {
        width: 95vw;
    }

    @media screen and (max-width: 430px) {
        height: 70vh;
    }
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 25px;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    
    span{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        color: whitesmoke;
    }
    button{
        margin: auto 10px auto auto;
        background: #D9D9D9;
        border: 1px solid black;
        padding: 5px;
        width: 25px;
        height: 25px;
        pointer-events: all;
        cursor: pointer; 
        img{
            width: 100%;
            height: 100%;
        }
    }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 10px;

    span {
        position: absolute;
        top: 10px;
        right: 10px;
        font-weight: bolder;
    }

    img {
        height: 70%;
        width: auto;
    }

    @media screen and (max-width: 900px) {
        height: 60px;
        margin-bottom: 10px;
    }
`;

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    &::after {
        content: "";
        position: absolute;
        top: -20px;
        background: linear-gradient(
        90deg,
        #0b1fd7 -0.05%,
        #b0b8ff 51.53%,
        #0b1fd7 99.98%
        );
        width: 100%;
        height: 20px;
    }
    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

const ContentSubLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: #4a6fce;
    width: 35%;
    height: 100%;
    padding: 20px;
    pointer-events: none;

    button {
        text-align: left;
        background: none;
        border: none;
        text-decoration: underline;
        margin: 10px;
        font-size: 1.3em;
        font-weight: 400;
        color: whitesmoke;
        pointer-events: all;
        cursor: pointer;
    }
    @media screen and (max-width: 900px) {
        padding: 10px;
        height: fit-content;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        span,
        input {
            display: none;
        }
        button {
            font-size: large;
        }
    }
    @media screen and (max-width: 520px) {
        button {
            font-size: medium;
        }
    }
    @media screen and (max-width: 400px) {
        button {
            font-size: small;
        }
    }
`;

const ContentSubRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    padding: 10px;
    img {
        position: absolute;
        top: 20%;
        right: 5%;
        width: 75%;
        height: auto;
        z-index: 0;

        @media screen and (max-width: 1200px) {
            width: 80%;
        }
        @media screen and (max-width: 500px) {
            width: 85%;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 90%;
    }

    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;

const CSRHeading = styled.h2`
    margin: 10px auto 5px 40px;
    font-size: xx-large;
`;

const CSRText = styled.p`
    margin: 0 auto 0 20px;
    font-size: normal;
    width: 55%;
    
    @media screen and (max-width: 700px) {
        width: 85%;
    }
`;

const CSRFooter = styled.span`
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: x-small;
    width: 50%;
    @media screen and (max-width: 700px) {
        width: 85%;
    }
`;

const Checkbox = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    input{
        margin-right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer; 
    }
    span{
        font-size: 1rem;
    }
`;

const GettingStarted = ({ taskStack, activateTask, deactivateTask, id, pushZStack }) => {
 
    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    
    
    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    useEffect(()=>{
        const mainWindow = mainWindowRef.current
        if (window.innerWidth<=1000) {
          mainWindow.style.transform = `translate3d(10px,100px,0)`;
        } else {
          mainWindow.style.transform = `translate3d(20vw,10vh,0)`;
        }

        return ()=> {
            clearInterval(()=>activateTask(4, 1), 300)
            clearInterval(()=>activateTask(0, 1), 300)
            clearInterval(()=>activateTask(8, 1), 300)
        }
    },[])


    return (
      <ContainerDiv ref={mainWindowRef} onClick={() => pushZStack(id)}>
        <Header
          onPointerDown={(e) => {
            toggleWindow(true);
            onClickOffset(e);
          }}
          onPointerUp={() => {
              toggleWindow(false);
          }}
          onTouchMove={(e) => {
            toggleWindow(true);
            onClickOffset(e);
          }}
          onTouchEnd={() => {
            toggleWindow(false);
          }}
        >
          <span>Getting started with Barto</span>
          <button onClick={() => deactivateTask(id)}>
            <img src={closeIcon} />
          </button>
        </Header>
        <SubHeader>
          <img src={logo} />
          <span>Windows</span>
        </SubHeader>
        <Content>
          <ContentSubLeft>
            <button onClick={() => setTimeout(()=>activateTask(4, 1), 300)}>Experience & Skills</button>
            <button onClick={() => setTimeout(()=>activateTask(0, 1), 300)}>Portfolio / Projects</button>
            {/* <button onPointerDown={() => activateTask(6, 1)}>Gallery</button> */}
            <button onClick={() => setTimeout(()=>activateTask(8, 1), 300)}>Contact Me</button>
            <Checkbox>
              <input type="checkbox" checked />
              <span>Show this at startup</span>
            </Checkbox>
          </ContentSubLeft>
          <ContentSubRight>
            <CSRHeading>Franklin Barto</CSRHeading>
            <CSRText>
              A <b>Software Developer</b> based in
              <br/> Nairobi, Kenya.
            </CSRText>

            <CSRFooter>
              FranklinBarto.com is a fun project I decided to make to share more about
              myself. You could say it is my portfolio! 
            </CSRFooter>
            <figure>
              <picture>
                <img src={frank} alt="Franklin Kipkalis Barto with caption" />
              </picture>
            </figure>
          </ContentSubRight>
        </Content>
      </ContainerDiv>
    );
}

export default GettingStarted
