import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import closeIcon from '../assets/icons/close_icon.png'
import EmailIcon from '../assets/icons/email_icon.png'
import logo from '../assets/logos/logo_horizontal.png'

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 45vw;
    min-height: 30vh;
    background: #fff;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;
    padding-bottom: 35px;
    pointer-events: all;

    @media screen and (max-width: 900px) {
        width: 75vw;
    }

    @media screen and (max-width: 700px) {
        width: 95vw;
    }

    @media screen and (max-width: 700px) {
        width: 95vw;
    }
`

const Header = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
        padding: 5px 0 5px 15px;
        justify-content: end;
        align-items: center;
        pointer-events: all;
        
        span.header{
            pointer-events: none;
            color: black;
            font-size: 1.2rem;
            margin: 0 auto 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            pointer-events: none;
            color: whitesmoke;
            img{
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }

        button{
            margin: auto 10px auto 1px;
            background: #D9D9D9;
            border: 1px solid black;
            padding: 5px;
            width: 25px;
            height: 25px;
            pointer-events: all;
            cursor: pointer; 
            img{
                width: 100%;
                height: 100%;
            }
        }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px;
    pointer-events: all;

    span{
        position: absolute;
        top: 10px;
        right: 10px;
        font-weight: bolder;
    }
    img{
        width: auto;
        height: 60px;
        margin-bottom: 5px;
    }
    @media screen and (max-width: 900px) {
        width: 80%;
        margin-bottom: 10px;   
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    &::after{
        content: '';
        position: absolute;
        top: -20px;
        background: linear-gradient(90deg, #0B1FD7 -0.05%, #B0B8FF 51.53%, #0B1FD7 99.98%);
        width: 100%;
        height: 20px;
    }
    h1{
        margin: 30px 0 10px 50px;
        font-size: xx-large;
    }
    h2{
        font-size: large;
        margin: 20px 0 5px 45px;
    }
    p{
        margin-left: 40px;
        width: 70%;
    }
    a{
        pointer-events: all;
        cursor: pointer; 
    }
`

const MailForm = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 50px;
    input{
        font-size: medium;
        width: 40%;
        height: 30px;
        padding 10px;
        margin: 5px;
        background: none;
        border: 1px solid black;
        pointer-events: all;
        &::placeholder{
            color: black;
        }
    }
    textarea{
        font-size: medium;
        width: 75%;
        height: 15vh;
        padding: 10px;
        margin: 5px;
        background: none;
        border: 1px solid black;
        pointer-events: all;
        &::placeholder{
            color: black;
        }
    }
`

const BackBtn = styled.button`
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: large;
    padding: 10px;
    width: fit-content;
    margin: 10px 0 0 55px;
    background: none;
    border: 1px solid black;
    cursor: pointer;
`

const Email = ({ deactivateTask, id, pushZStack, backPress }) => {
 
    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    

    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }

    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    return (
        <ContainerDiv ref={mainWindowRef} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"><img src={EmailIcon} alt="email logo"/>Lets Talk!</span>
                <button onClick={()=>deactivateTask(id)}><img src={closeIcon}/></button>
            </Header>
            <SubHeader>
                <img src={logo}/>
                <span>Windows</span>
            </SubHeader>
            <Content>
                <h1>Start a Conversation</h1>
                <h2>Send Me Some Mail</h2>
                <p>I typically respond within a day<br/>
                   <a href="mailto:hello@franklinbartoo.com">hello@franklinbarto.com</a>
                </p>
                <h2>Find Me on Socials</h2>
                <p>I'm mainly on here for news, memes and networking<br/>
                    <a href="https://twitter.com/franklinkbarto">twitter</a> / <a href="https://www.linkedin.com/in/frankbarto/">linkedin</a>
                </p>
                {/* <MailForm>
                    <input type="email" placeholder='name@email.com'/>
                    <input type="text" name="subject" placeholder="What topic do you want to discuss?"/>
                    <textarea name="message" placeholder='Hello Franklin, my name is ...'></textarea>
                </MailForm>
                <SendMailBtn>Send Message</SendMailBtn> */}
                <BackBtn onClick={()=>backPress(id)}>Back</BackBtn>
            </Content>
        </ContainerDiv>
    )
}

export default Email
