import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import promptIcon from '../assets/icons/prompt_icon.png'
import portrait from '../images/frank_no_bg.png'

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: auto;
    background: #D9D9D9;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;
    ${props=>props.minimize?'width: 60vw;':'width: 100%;'}
    ${props=>props.minimize?'height: auto;':'height: 100%;'}
`

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 15px;
    justify-content: end;
    align-items: center;
    pointer-events: all;
    
    span.header{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: whitesmoke;
        img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
        }
    }

    button{
        margin: auto 10px auto 1px;
        background: #D9D9D9;
        border: 1px solid black;
        padding: 5px;
        width: 25px;
        height: 25px;
        pointer-events: all;
        cursor: pointer; 
        img{
            width: 100%;
            height: 100%;
        }
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    ${props=>props.minimize?'height: 60vh;':'height: 100%;'}
    overflow-Y: scroll;
    display: flex;
    flex-direction: column;
    background: black;
    margin-top: 2px;
    border: 1px solid black;
    pointer-events: all;
    .title{
        color: white;
        margin: 40px 0 10px 20px;
    }
    .bash{
        color: white;
        margin: 20px;
        .green{
            color: var(--color-green);
        }
        .blue{
            color: var(--color-blue);
        }
        .orange{
            color: var(--color-orange);
        }
        .red{
            color: var(--color-red);
        }
        input{
            border: none;
            background: none;
            color: white;
            width: fit-content;
        }
    }
    .cursor{
        color: white;
    }
    .codelines{     
        counter-reset: item;
        list-style-type: none;
        color: white;
        margin-left: 20px;
        li { 
            display: flex;
            margin: 5px;
            font-size: normal;
        }
        li:before { 
            color: white;
            width: 30px;
            content: counter(item) " "; 
            counter-increment: item 
        }

        .green{
            color: var(--color-green);
        }
        .neonBlue{
            color: var(--color-neon-blue);
        }
        .blue{
            color: var(--color-blue);
        }
        .orange{
            color: var(--color-orange);
        }
        .red{
            color: var(--color-red);
        }
    }
    .portrait{
        position: relative;
        background-image: url(${portrait});
        background-size: cover;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 80%;
        height: 75vh;
    }
`

const Cursor = ()=>
{
    const [content,setContent] = useState(false)
    useEffect(() => {
        const interval = setInterval(() => {
            setContent(curr=>!curr)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return(
        <span className='bash'> <span className='green'>C://B4rto@Doors <span className="blue">:~$</span></span> &nbsp;{content?"|":""}</span>
    )
}

const Bash = ({ deactivateTask, id, pushZStack, appendToMinimize}) => {

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})
    const [minimize,toggleMinimize] = useState(true)
    const mainWindowRef = useRef()

    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x= -(mainWindow.getBoundingClientRect().x - e.clientX)
        var y = -(mainWindow.getBoundingClientRect().y - e.clientY)
        updateOffset({x,y})
    }
    
    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }

    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX = e.clientX - offset.x
        var newPosY = e.clientY - offset.y
        
        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> window.removeEventListener('mousemove',handleWindowMove)
    },[moveWindow])

    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={promptIcon} alt="pc icon" />Command Prompt</span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button  onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <Content minimize={minimize}>
                <p className='title'>FranklinBarto.com (R) Doors 2000 <br/> (c) Copyright? Just reach out/ give a shout out if this helped you out in some way!</p>
                <span className='bash'> <span className='green'>C://Barto@Doors <span className="blue">:~$</span></span> &nbsp;&nbsp; cat -n /home/barto/about.js</span>
                <ol className='codelines'>
                    
                    {/*
                    some character codes i need
                     &nbsp; - space
                     &#123; - {
                     &#125; - }
                    */}

                    <li className='green'>/*</li>
                    <li className='green'>&nbsp;&nbsp;Saving this for later</li>
                    <li className='green'>*/</li>
                    <li></li>
                    <li><span className='blue'>class</span> &nbsp; <span className='green'>Franklin Barto</span>&nbsp;( ) &#123;</li>
                    <li></li>
                    <li>&nbsp;&nbsp;<span className='orange'>constructor</span>&nbsp;( ) &#123;</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>name</span> &nbsp;=&nbsp; <span className='green'>'Franklin Barto'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>DOBTimestamp</span> &nbsp;=&nbsp; <span className='green'>'1996-08-05T19:20:30.45+03:00'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>email</span> &nbsp;=&nbsp; <span className='green'>'hello@franklinbarto.com'</span></li>
                    <li>&nbsp;&nbsp;&#125;</li>
                    <li></li>
                    <li>&nbsp;&nbsp;<span className='neonBlue'>Education</span>&nbsp;( ) &#123;</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>return</span> &nbsp;[</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Undergrad</span> &nbsp;=&nbsp; <span className='green'>'Strathmore University, BSC. Telecommunications'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Highschool</span> &nbsp;=&nbsp; <span className='green'>'Shah Lalji Nangpar Academy, IGCSE'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;]</li>
                    <li>&nbsp;&nbsp;&#125;</li>
                    <li></li>
                    <li>&nbsp;&nbsp;<span className='neonBlue'>Work Experience</span>&nbsp;( ) &#123;</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>return</span> &nbsp;[</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>KenyaSpaceAgency.SoftwareDeveloper</span> &nbsp;=&nbsp; <span className='green'>'July 2022-ongoing'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>KenyaSpaceAgency.ICTIntern</span> &nbsp;=&nbsp; <span className='green'>'June 2021-June 2022'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>DigitizeAfricaOnline.Freelance</span> &nbsp;=&nbsp; <span className='green'>'June 2020-June 2021'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>JamiiTelecommunicationsLTD.ICTIntern</span> &nbsp;=&nbsp; <span className='green'>'June 2020-June 2021'</span></li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;]</li>
                    <li>&nbsp;&nbsp;&#125;</li>
                    <li></li>
                    <li>&nbsp;&nbsp;<span className='neonBlue'>Skills</span>&nbsp;( ) &#123;</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>return</span> &nbsp;[</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Programming</span> &nbsp;=&nbsp; [<span className='green'>'Python', 'Javascript', 'Typescript', 'Java', 'C', 'Html', 'CSS'</span>]</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Dev Ops</span> &nbsp;=&nbsp; [<span className='green'>'Docker', 'VmWare (vCenter, esxi)', 'Git', 'Linux'</span>]</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Database</span> &nbsp;=&nbsp; [<span className='green'>'Postgres', 'Mysql', 'MongoDB', 'firebase'</span>]</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>Product Design</span> &nbsp;=&nbsp; [<span className='green'>'Figma', 'Adobe XD', 'Inkscape', 'Blender', 'GIMP'</span>]</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>this.</span><span className='green'>CMS</span> &nbsp;=&nbsp; [<span className='green'>'Drupal', 'Wordpress', 'Moodle'</span>]</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;]</li>
                    <li>&nbsp;&nbsp;&#125;</li>
                    <li></li>
                    <li>&nbsp;&nbsp;<span className='neonBlue'>To Do List</span>&nbsp;( ) &#123;</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>return</span> &nbsp;[</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='green'>Learn micro-sevices, probably spring boot</span>,</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='green'>Learn to create production level ML models</span>,</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='green'>Create a fraud detection python script</span>,</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='green'>Implement a domain ranking algo</span>,</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;]</li>
                    <li>&nbsp;&nbsp;&#125;</li>
                    <li>&#125;</li>
                </ol>
                <span className='bash'> <span className='green'>C://Barto@Doors <span className="blue">:~$</span></span> &nbsp;&nbsp; cat /home/barto/portrait.png</span>
                <span className='portrait'>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                
                </span>
                <Cursor/>
            </Content>
           
        </ContainerDiv>
    )
}

export default Bash