import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import internetIcon from '../assets/icons/internet_icon.png'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'
import gitIcon from '../assets/icons/social/github.png'
import twIcon from '../assets/icons/social/twitter.png'


import background from '../assets/gallery/barto_un.jpg'
import backgroundRect from '../assets/background/tommorownow_rect.png'

import project1 from '../assets/portfolio/franklinbartofigma.png'
import project2 from '../assets/portfolio/gsh.png'
import project3 from '../assets/portfolio/netflix.png'
import project4 from '../assets/portfolio/sorting.png'
import project5 from '../assets/portfolio/loft.png'
import project6 from '../assets/portfolio/plugmali.png'
import project7 from '../assets/portfolio/langmap.png'
import project8 from '../assets/portfolio/moon.png'
import project9 from '../assets/portfolio/pomodoro.png'
import project10 from '../assets/portfolio/askari.png'

    const ContainerDiv = styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        ${props=>props.minimize?'width: 80vw;':'width: 100%;'}
        ${props=>props.minimize?'height: 85vh;':'height: 100%;'}
        background: #D9D9D9;
        z-index: 100;
        border: 1px solid black;
        padding: 5px;
        pointer-events: all;
        @media screen and (max-width:700px){
            ${props=>props.minimize?'width: 95vw;':'width: 100%;'}
        }
    `

    const Header = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
        padding: 5px 0 5px 15px;
        justify-content: end;
        align-items: center;
        pointer-events: all;
        
        span.header{
            pointer-events: none;
            color: black;
            font-size: 1.2rem;
            margin: 0 auto 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            pointer-events: none;
            color: whitesmoke;
            img{
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }

        button{
            margin: auto 10px auto 1px;
            background: #D9D9D9;
            border: 1px solid black;
            padding: 5px;
            width: 25px;
            height: 25px;
            pointer-events: all;
            cursor: pointer; 
            img{
                width: 100%;
                height: 100%;
            }
        }
    `

    const SubHeader = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        border: 1px solid black;
        background-color: #D9D9D9;
        align-items: center;
        span.subHeader{
        }
    `
    const Divider = styled.div`
        position: relative;
        width: 3px;
        height: 90%;
        background-color: #A9A9A9;
        margin: auto 5px;
    `

    const LinkItem = styled.button`
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        color: gray;
        img{
            height: 15px;
            margin-right: 5px;
        }
        &.back{
            color: black;
        }
        `
    const LogoDiv = styled.div`
        position: relative;
        margin: auto 0 auto auto;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100px;
        img{
            height: 70%;
        }
    
    `

    const Address = styled.div`
        position: relative;
        margin: auto auto auto 15px;
        height: 90%;
        width: 60%;
        background: white;
        display: flex;
        flex-direction: row;
        border: 1px solid black;
        align-items: center;
        
        img{
            height: 80%;
            margin-right: 5px;
            margin-left: 5px;
        }

        span.address{
            margin: auto auto auto 2px;
        }

        @media screen and (max-width:700px){
            font-size: small;
        }
    `

    const Content = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        margin-top: 2px;
        border: 1px solid black;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 10px;
        pointer-events: all;
    `

    const Portfolio = styled.div`
        position: relative;
        width: 100%;
        height: 200vh;
        display: flex;
        flex-direction: column;
        background: #fff;
        pointer-events: all;
        z-index: 10;
    `

    const TopContent = styled.div`
        position: relative;
        top: 0;
        left: 0;
        background: #4A6FCE;
        width: 100%;
        height: 500px;
    `

    const Title = styled.div`
        position: relative;
        margin-top: 100px;
        text-align: center;
        h2{
            color: whitesmoke;
            margin: 20px;
        }
        h1,span,p{
            color: whitesmoke;   
        }

        @media screen and (max-width:700px){
            h1{
                font-size: x-large;    
            }
            h2,span,p{   
                font-size: small;    
            }

        }
        
    `

    const Socials = styled.div`
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        flex-direction: row;
        button{
            pointer-events: all;
            background: none;
            border: none;
            margin: 5px;
            cursor: pointer;
        }
    `

    const Doubles = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 150px;
        margin-top: 50px;

        @media screen and (max-width:700px){
            margin-bottom: 140px;
        }
        
    `

    const HeroImg = styled.div`
        position: relative;
        background: red;
        z-index: 100;
        margin: 10px auto;
        width: 300px;
        height: 500px;
        border: 0.646116px solid #000000;
        border-radius: 170.584px;
        transform: translateY(-100px);
        img{
            border-radius: 170.584px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 125%;
            height: 120%;
            border: 0.646116px solid #000000;
            border-radius: 170.584px;
            background-color: none;
        }

        @media screen and (max-width:700px){
            font-size: small;
            width: 120px;
            height: 200px;
        }
    `

    const ContentLeft = styled.div`
        position: relative;
        margin: 10px auto 10px 30px;
        width: 30%;
        text-align: center;

        h2{
            color: whitesmoke;
        }
        p{
            color: whitesmoke;   
        }
        @media screen and (max-width:700px){
            padding:10px;
            text-align: left;
            font-size: x-small;
        }
    `
    
    const ContentRight = styled.div`
        position: relative;
        margin: 10px 30px 10px auto;
        width: 30%;
        text-align: center;
        
        h2{
            color: whitesmoke;
        }
        p{
            color: whitesmoke;   
        }
        @media screen and (max-width:700px){
            text-align: right;
            padding: 10px;
            font-size: x-small;    
        }
    `

    const BottomContent = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        z-index: 100;
        margin-top: 50px;

        @media screen and (max-width:700px){
            margin-top: 100px;
            font-size: small;
        }
    `

    const BCLeft = styled.div`
        height: 40%;
        width: 250px;
        margin: 10px auto 10px 7%;
    `
    const BCRight = styled.div`
        text-align: right;
        height: 40%;
        width: 250px;
        margin: 10px 7% 10px auto;
    `

    const BCItem = styled.div`
        position: relative;
        margin: 40px 0;
    `

    const BCFooter = styled.span`
        position: relative;
        text-align: center;
        margin: 80px auto 10px auto;
        width: 80%;
    `

    const Works = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 30vh;
        padding-top: 50vh;
        padding-bottom: 10vh;
        background: #4A6FCE;

        span{
            z-index: 10;
        }
        &::before{
            content: "";
            background: white;
            position: absolute;
            top: -20vh;
            left: 0;
            width: 200vw;
            height: 50vh;
            transform: translateX(-25%) rotate(-8deg);
            z-index: 5;
        }

        @media screen and (max-width:700px){
            padding-top: 60vh;
            .heroImg{
                width: 75vw;
                height: 50vh;
            }
        }
    `

    const WorkItem = styled.div`
        position: relative;
        width: 90%;
        height: auto;
        margin: 10px auto 50px auto;
        border-top: 1px solid whitesmoke;
        display: flex;
        flex-direction: column;
        z-index: 10;

        .header{
            position: relative;
            display: flex;
            width: 100%;
            margin: 10px auto 30px auto;
            span{
                font-size: larger;
                color: white;
            }
            span:nth-child(1){
                margin: auto auto auto 20px;
            }
            span:nth-child(2){
                margin: auto 20px auto auto;
            }
        }

        .content{
            position: relative;
            z-index: 10;
            width: 100%;
            display: flex;
            flex-direction: row;
            .left{
                position: sticky;
                top: 5%;
                width: 40%;
                height: fit-content;
                margin: 10px auto;
                padding: 20px;

                h2{
                    color: white;
                    margin: 10px 20px;
                }
                p{
                    color: white;
                    margin-bottom: 30px;
                }
                .item{
                    position: relative;
                    display: flex;
                    width: 100%;
                    margin-bottom: 10px;
                    &::before{
                        content: "";
                        position: absolute;
                        top: -5px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 1px;
                        background: whitesmoke;
                        z-index: 10;
                    }
                    a{
                        pointer-events: all;
                        color: white;
                        text-decoration: none;
                        cursor: pointer;
                        font-size: large;
                    }
                    span{
                        color: white;
                    }
                    span:nth-child(1){
                        margin: 5px auto 5px 10px;
                    }
                    span:nth-child(2){
                        margin: 5px 10px 10px auto;
                    }
                }
            }
            .right{
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 60%;
                margin: 10px auto;
                justify-content: center;
                img{
                    object-fit: cover;
                    width: 90%;
                    height: 110vh;
                    margin: auto;
                }
            }
        }

        @media screen and (max-width:700px){
            .header{
                font-size: small;
            }
            .content{
                flex-direction: column;
                .left{
                    font-size: small;
                    padding:5px;
                    position: relative;
                    width: 100%;
                }
                .right{
                    font-size: small;
                    padding: 5px;
                    width: 100%;
                    img{
                        margin-top: 20px;
                        object-fit: cover;
                        width: 100%;
                        height: 200px;
                        margin: auto;
                    }
                }
            }
        }
        
    `
    const HeaderText = styled.div`
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        width: 50vw;
        height: 70vh;
        z-index: 10;

        
        @media screen and (max-width:700px){
            width: 90%;
            h2{
                font-size: 3rem;
            }

            h4{
                font-size: 1rem;
            }
        }

        h2{
            font-size: 7rem;
            color:rgba(11,74,206,0.8);
        }

        h4{
            font-size: 2rem;
            color:rgba(11,74,206,0.5);
        }
    `

    const ContentFooter = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        div{
            height: 100%;
            width: 60%;
            border: 1px solid black;
            display: flex;
            align-items: center;
            padding-left: 5px;
            &:nth-child(2){
                width: 39%;
                margin: 0 0 0 auto;
                img{
                    height: 80%;
                    margin: auto 5px;
                }
            }
        }
    `

const PortfolioExplorer = ({ id, pushZStack, deactivateTask, backPress, appendToMinimize }) => {

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    const [minimize,toggleMinimize] = useState(true)

    const [hideWindow,toggleHideWindow] = useState(true)

    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }

     //offset works
     const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} hideWindow={hideWindow} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={internetIcon} alt="pc icon" /> My Portfolio </span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem>Home</LinkItem>
                <LinkItem>Open</LinkItem>
                <LinkItem>File</LinkItem>
                <LinkItem>Settings</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>

            <SubHeader>
                <Divider/>
                <LinkItem className='back' onClick={()=>backPress(id)}> <img src={backIcon} alt="back icon"/> back</LinkItem>
            </SubHeader>

            <SubHeader>
                <Divider/>
                <span className="subHeader">Address</span>
                <Address>
                    <span className="address">c://Desktop/My Portfolio.html</span>
                </Address>
            </SubHeader>
            <Content>
              <Portfolio>
                <TopContent>
                    <Socials>
                        <button onClick={()=>{window.open("https://twitter.com/franklinkbarto")}}>
                            <img src={twIcon} alt="twitter icon" />
                        </button>
                        <button onClick={()=>{window.open("https://github.com/Frankothe196")}}>
                            <img src={gitIcon} alt="github icon" />
                        </button>
                    </Socials>
                    <Title>
                        <h1>Franklin Barto</h1>
                        <h2>Software Developer</h2>
                        <span>Based in Nairobi Kenya</span>
                    </Title>
                    <Doubles>
                        <ContentLeft>
                            <h2>Programming</h2>
                            <p>With 4 years of professional experience. <br/> I enjoy working with javascript, python, php and java</p>
                        </ContentLeft>
                        <ContentRight>
                            <h2>Product Development</h2>
                            <p>Human Centric paradigm <br/> in my design process to create great user experiences.</p>
                        </ContentRight>
                    </Doubles>
                    <HeroImg>
                        <img src={background} alt="Franklin Barto portrait"/>
                    </HeroImg>
                </TopContent>
                <BottomContent>
                    <BCLeft>
                        <BCItem>
                            <h3>Data Engineering</h3>
                            <p>Transforming raw data into valuable insights</p>
                        </BCItem>
                        <BCItem>
                            <h3>AI/ ML</h3>
                            <p>Drive innovation and solve complex problems</p>
                        </BCItem>
                    </BCLeft>
                    <BCRight>
                        <BCItem>
                            <h3>Product Management</h3>
                            <p>Ensure optimal Time and Resource management</p>
                        </BCItem>
                        <BCItem>
                            <h3>App Security</h3>
                            <p>Building resilient secure apps</p>
                        </BCItem>
                    </BCRight>
                </BottomContent>
                <BCFooter>Though not exhaustive, above are areas I have experience in. <br/> Below are some previous works.</BCFooter>
              </Portfolio>
              <Works>
                <HeaderText>
                    <h2>Web Projects</h2>
                    <h4>Some of my personal projects </h4>
                </HeaderText>
                {/* <img className="heroImg" src={backgroundRect} alt="Franklin Barto" />   */}
                <WorkItem>
                    <div className='header'>
                        <span>Plug Mali</span>
                        <span>2024</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>Vehicle Auctions Tool</h2>
                            <p>This project involves web scraping to conduct a daily scan of the Kenyan internet for active vehicle auctions. The scraped data is then presented through a tool that empowers users to access, search, filter, and sort the dataset with ease.</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>React (Gatsby), Python</span>
                            </div>
                            <div className='item'>
                                <span>Tools</span>
                                <span>Vercel, Google Cloud</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://plug-mali.vercel.app/">View live site</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project6} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>

                <WorkItem>
                    <div className='header'>
                        <span>Cloud Watch</span>
                        <span>2024</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>Askari-Cloud Watch</h2>
                            <p>Inspired by AWS's server health monitoring tool, this project aims to provide a personalized solution for monitoring server health and receiving timely alerts. Designed for personal use, the tool monitors server status and sends alerts when any abnormalities are detected. While the project is mostly complete, current circumstances have necessitated setting it aside temporarily. However, it remains poised for future development and enhancements.</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>React (Nextjs), Python</span>
                            </div>
                            <div className='item'>
                                <span>Tools</span>
                                <span>Vercel</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://askari-cloud-watch.vercel.app/">View live site</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project10} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>

                <WorkItem>
                    <div className='header'>
                        <span>3D Landing Page</span>
                        <span>2024</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>Moon Landing Page</h2>
                            <p>Developed an immersive 3D Moon Theme Landing Page, leveraging WebGL and Three.js technologies to create a visually captivating user experience.</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>React, Three.js</span>
                            </div>
                            <div className='item'>
                                <span>Tools</span>
                                <span>Git Pages</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://frankothe196.github.io/moon-mission-landing-page/">View live site</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project8} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>
                
                <WorkItem>
                    <div className='header'>
                        <span>Pomodoro App</span>
                        <span>2023</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>ProdBoost - Pomodoro</h2>
                            <p>I created this pomodoro time management app to help better manage my focus time. I also used this opportunity as a refresher for web animations.</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>React</span>
                            </div>
                            <div className='item'>
                                <span>Tools</span>
                                <span>Vercel</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://pomodoro-livid-two.vercel.app/">View live site</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project9} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>

                <WorkItem>
                    <div className='header'>
                        <span>FranklinBarto.com</span>
                        <span>2023</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>FranklinBarto.com</h2>
                            <p>My personal website, since you are already interacting with the website, here is design process behind it which was done in figma.</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Product Designer</span>
                            </div>
                            <div className='item'>
                                <span>Tools</span>
                                <span>Figma,Inkscape,Gimp</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://www.figma.com/file/3iIXu9nsEQ5gm3wthWPsT8/B4RTO?node-id=0%3A1&t=K2N2l9yct4MYRt10-1">View Case Study</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project1} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>

                <WorkItem>
                    <div className='header'>
                        <span>World Language Map</span>
                        <span>2023</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>World Language Map</h2>
                            <p> This app visually explores the diversity of languages on our planet. Its users discover the beauty of linguistic diversity in a smart/modern way!</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>React, OpenLayers, Python</span>
                            </div>  
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://frankothe196.github.io/react-language-map/">View live site</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project7} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>

                <WorkItem>
                    <div className='header'>
                        <span>Netflix Clone</span>
                        <span>2019</span>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <h2>Netflix Clone</h2>
                            <p>This was one of my early attempts at cloning and app. A demonstration of a cyber security attack</p>
                            <div className='item'>
                                <span>Role</span>
                                <span>Developer</span>
                            </div>
                            <div className='item'>
                                <span>Stack</span>
                                <span>HTML,CSS,Vanilla JS</span>
                            </div>
                            <div className="item">
                                <a target="_blank" rel="noreferrer" href="https://frankothe196.github.io/netflix/">View Repo</a>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={project3} alt="icon"/>
                        </div>
                    </div>
                </WorkItem>
              </Works>
            </Content>
            <ContentFooter>
                <div><span>1 Object(s)</span></div>
                <div><img src={internetIcon} alt="icon"/> My Portfolio</div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default PortfolioExplorer