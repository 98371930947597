import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'
import picIcon from '../assets/icons/pictures_icon.png'


//Gallery images
import img1 from '../assets/gallery/barto_un.jpg'
import img2 from '../assets/gallery/adanianlabs.jpg'
import img3 from '../assets/gallery/CEO_KSA_1.jpg'
import img4 from '../assets/gallery/CEO_KSA_2.jpg'
import img5 from '../assets/gallery/tommorowNow.jpg'
import img6 from '../assets/gallery/Me_Speaking.jpg'
import img7 from '../assets/gallery/workshop.jpg'
import img8 from '../assets/gallery/afrirobot.jpg'
import img9 from '../assets/gallery/randomMeeting.jpg'
import img10 from '../assets/gallery/business_card.jpg'
import img11 from '../assets/gallery/dr_val.jpg'
import img12 from '../assets/gallery/guns.jpg'


const galleryData = 
    [
        {image:img1, title:"Working from the UN HQ.jpg"},
        {image:img2, title:"Visiting John from Adanian Labs.jpg"},
        {image:img3, title:"Receiving our CEO KSA at our booth 1.jpg"},
        {image:img4, title:"Receiving our CEO KSA at our booth 2.jpg"},
        {image:img5, title:"tommorowNow Team Designing.jpg"},
        {image:img6, title:"Sharing my views.jpg"},
        {image:img7, title:"Tomorrownow Team Designing 1.jpg"},
        {image:img8, title:"Discussing Tech with Jasin of Afrirobot.jpg"},
        {image:img9, title:"Random meeting at work.jpg"},
        {image:img10, title:"My first business card design.jpg"},
        {image:img11, title:"Dr. Val Munsami introduced me to the inner workings of policy.jpg"},
        {image:img12, title:"guns!.jpg"},
    ]

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #D9D9D9;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;
    pointer-events: all;
    ${props=>props.minimize?'width: 60vw;':'width: 100%;'}
    ${props=>props.minimize?'height: 80vh;':'height: 100%;'}
    @media screen and (max-width:700px){
        ${props=>props.minimize?'width: 95vw;':'width: 100%;'}
    }
`

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 15px;
    justify-content: end;
    align-items: center;
    pointer-events: all;
    
    span.header{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: whitesmoke;
        img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
        }
    }

    button{
        margin: auto 10px auto 1px;
        background: #D9D9D9;
        border: 1px solid black;
        padding: 5px;
        width: 25px;
        height: 25px;
        pointer-events: all;
        cursor: pointer; 
        img{
            width: 100%;
            height: 100%;
        }
    }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    background-color: #D9D9D9;
    align-items: center;
    span.subHeader{
    }
`

const Divider = styled.div`
    position: relative;
    width: 3px;
    height: 90%;
    background-color: #A9A9A9;
    margin: auto 5px;
`

const LinkItem = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-left: 10px;
    padding: 0;
    border: none;
    background: none;
    color: gray;
    cursor: pointer;
    img{
        height: 15px;
        margin-right: 5px;
    }
    &.back{
        color: black;
    }
`

const LogoDiv = styled.div`
    position: relative;
    margin: auto 0 auto auto;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    img{
        height: 70%;
    }

`

const Address = styled.div`
    position: relative;
    margin: auto auto auto 15px;
    height: 90%;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    align-items: center;
    
    img{
        height: 80%;
        margin-right: 5px;
        margin-left: 5px;
    }

    span.address{
        margin: auto auto auto 2px;
    }

    @media screen and (max-width:700px){
        font-size: small;
    }
`

const ContentFooter = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    div{
        height: 100%;
        width: 60%;
        border: 1px solid black;
        display: flex;
        align-items: center;
        padding-left: 5px;
        &:nth-child(2){
            width: 39%;
            margin: 0 0 0 auto;
            img{
                height: 80%;
                margin: auto 5px;
            }
        }
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: white;
    margin-top: 2px;
    border: 1px solid black;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    pointer-events: all;
`

const Item = styled.button`
    position: relative;
    height: 85px;
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    cursor: pointer;
    background: none;
    border: none;

    img{
        width: 50px;
        height: 50px;
        margin: 5px auto;
    }

    span.itemTitle{
        color: black;
        white-space: nowrap;
    }
`
const GalleryItemStyle = styled.button`
    position: relative;
    margin: 5px auto;
    width: 31%;
    height: 30vh;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;

    img{
        width: 100%;
        height: 80%;
        object-fit: cover;
    }
    span{
        margin-left: 5px;
    }

`



const DesktopItem = ({icon,title})=>(
    <Item>
        <img src={icon} alt="title" />
        <span className="itemTitle">{title}</span>
    </Item>
)

const Gallery = ({ activateTask, deactivateTask, id, pushZStack, setActiveImage, appendToMinimize }) => {

    const GalleryItem = (props)=>{
        return(
            <GalleryItemStyle onPointerDown={()=>{setActiveImage({image: props.img, title: props.title});activateTask(7, id);}}>
                <img src={props.img} alt={props.title}/>
                <span>{props.title}</span>
            </GalleryItemStyle>
        )
    }

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})
    const mainWindowRef = useRef()

    const [minimize,toggleMinimize] = useState(true)

    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }
    
    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={picIcon} alt="pc icon" /> My Gallery</span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button  onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem>Home</LinkItem>
                <LinkItem>Open</LinkItem>
                <LinkItem>File</LinkItem>
                <LinkItem>Settings</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <LinkItem className='back' onClick={()=>activateTask(1,id)}> <img src={backIcon} alt="back icon"/> back home</LinkItem>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <span className="subHeader">Address</span>
                <Address>
                    <img src={picIcon} alt="pc icon"/>
                    <span className="address">c://My Gallery</span>
                </Address>
            </SubHeader>
            <Content>
                {galleryData&&galleryData.map((item)=><GalleryItem img={item.image} title={item.title}/>)}
            </Content>
            <ContentFooter>
                <div><span>8 Object(s)</span></div>
                <div><img src={picIcon} alt="icon"/> My Gallery</div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default Gallery