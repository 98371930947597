import React, {memo} from 'react'
import styled from 'styled-components'

import ground from '../assets/background/foreground.png'
import normalSky from '../assets/background/skyNormal.jpg'
import darkSky from '../assets/background/skyDark.jpg'

const Container = styled.div`
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`

const Sky = styled.img`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0%;
    width: 100vw;
    height: 100vh;
    padding-bottom: 80px;
    transition: 1s ease-in-out;
    object-fit: fill;
    user-select: none;
    opacity: ${props=> props.mode?1:0.5};
    -webkit-user-select: none; /*Safari*/
    -moz-user-select: none; /*Firefox*/
    &:focus{
        display: none;
    }
`

const Ground = styled.img`
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 105vw;
    height: 35vh;
    filter: brightness(${props=> props.mode?1:0.15}) blur(${props=> props.mode?0:'1.5px'});
    transition: 1s ease-in-out;
    user-select: none;
    -webkit-user-select: none; /*Safari*/
    -moz-user-select: none; /*Firefox*/
    &:focus{
        display: none;
    }
`

const Background = ({mode}) => {
    
    return (
        <Container>
            <Sky src={mode?normalSky:darkSky} mode={mode} alt="night sky wallpaper background"/>
            <Ground mode={mode} src={ground} alt="Ground wallpaper background"/>
        </Container>
    )
}

export default memo(Background)