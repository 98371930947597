import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ImgLogo from '../assets/logos/logo_1.png'

const LoadingScreen = ({setLoading}) => {
    const LoadingDiv = styled.section`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background: #f5f5f5;
        z-index: 1000;

        img{
            height: 50vh;
            max-height: 60vh;
            max-width: 70vw;
            margin: 15vh auto;
        }
    `
    
    const LoadingBar = styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 20vh;
        background: #D9D9D9;
        display: flex;
        flex-direction: column;
        padding: 10px;

        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 100vw;
            background: linear-gradient(90deg, #0038FF 11.39%, rgba(0, 10, 44, 0.5) 99.44%);
        }

        @media screen and (max-width: 500px){
            height: 30vh;
        }
    `

    const Bar = styled.div`
        position: relative;
        margin: 30px auto 10px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span{ 
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 29px;
            color: var(--color-black);
        }

        div{
            position: relative;
            height: 30px;
            width: 400px;
            margin-left: 5px;
            border: solid black 2px;
            display: flex;
            flex-direction: row;

            span{
                width: 25px;
                height: 85%;
                margin: auto 2px;
                background: #4B4B4B;
                
            }
        }

        @media screen and (max-width: 500px){
            div{
                height: 20px;
                width: 200px;
                span{
                    width: 12px;
                }
            }
        }
        
    `

    const SkipBtn= styled.button`
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin: 0 auto;
        border: none;
        background: none;
        font-size: large;
        cursor: pointer;
        pointer-events: all;

        @media screen and (max-width: 500px){
            right: 50%;
            transform: translate(50%,-70%);
        }
    `

    const Copy = styled.h2`
        position: relative;
        margin: 10px auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 29px;
        color: var(--color-black);

        @media screen and (max-width: 500px){
            font-size: x-small;
            width: 100%;
            text-align: center;
        }

        @media screen and (max-width: 500px){
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

    `

    const Title = styled.h2`
        position: absolute;
        height: 60px;
        right: 100px;
        top: 45px;
        font-family: 'Inter';
        font-style: italic;
        font-weight: bold;
        font-size: 2rem;
        line-height: 60px;
        color: var(--color-black);
    `

    const [loadingBar,setLoadingBar] = useState('')
    const [counter,setCounter] = useState(0)
    
    useEffect(()=>{
        if(counter<15){
            setCounter(counter+1)
            setTimeout(()=>{
                setLoadingBar(loadingBar+'<span></span>')
            },600)    
        }
    },[loadingBar])


    return (
        <LoadingDiv id='loading-screen'>
            <Title>Windows</Title>
            <img alt='windows doors logo' src={ImgLogo}/>
            <LoadingBar>
                <Bar>
                    <span>Starting up ...</span>
                    <div dangerouslySetInnerHTML={{__html: loadingBar}}>
                    </div> 
                </Bar>
                <SkipBtn onClick={()=>setLoading(false)}>skip loading screen ...</SkipBtn>
                <Copy>
                    Copyright @ 2023 FranklinBarto.com, a personal website 
                </Copy>
            </LoadingBar>
        </LoadingDiv>
    )
}

export default LoadingScreen