import React, { useEffect, useRef, useState, memo } from 'react'
import styled from 'styled-components'
import pcIcon from '../assets/icons/pc_icon.png'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'
import logoBanner from '../assets/logos/logo_horizontal.png'
import frank from '../assets/artboard/frank_1.png'
import fileIcon from '../assets/icons/documents_icon.png'
import trashIcon from '../assets/icons/trashcan_icon.png'

import picIcon from '../assets/icons/pictures_icon.png'

const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    background: #D9D9D9;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;
    ${props=>props.minimize?'width: 60vw;':'width: 100%;'}
    ${props=>props.minimize?'height: auto;':'height: 100%;'}

    @media screen and (max-width:700px){
        ${props=>props.minimize?'width: 95vw;':'width: 100%;'}
    }
`
const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 15px;
    justify-content: end;
    align-items: center;
    pointer-events: all;
    
    span.header{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: whitesmoke;
        img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
        }
    }

    button{
        margin: auto 10px auto 1px;
        background: #D9D9D9;
        border: 1px solid black;
        padding: 5px;
        width: 25px;
        height: 25px;
        pointer-events: all;
        cursor: pointer; 
        img{
            width: 100%;
            height: 100%;
        }
    }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    background-color: #D9D9D9;
    align-items: center;
    @media screen and (max-width: 900px) {
        height: 40px;
        margin-bottom: 1px;
        span{
            font-size: small;
        }
    }
`
const Divider = styled.div`
    position: relative;
    width: 3px;
    height: 90%;
    background-color: #A9A9A9;
    margin: auto 5px;
`

const LinkItem = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-left: 10px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: gray;
    img{
        height: 15px;
        margin-right: 5px;
    }
    &.back{
        color: black;
    }
    `

const LogoDiv = styled.div`
    position: relative;
    margin: auto 0 auto auto;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    img{
        height: 70%;
    }

`

const Address = styled.div`
    position: relative;
    margin: auto auto auto 15px;
    height: 90%;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    align-items: center;
    
    img{
        height: 80%;
        margin-right: 5px;
        margin-left: 5px;
    }

    span.address{
        margin: auto auto auto 2px;
    }

    @media screen and (max-width: 700px) {
        img{
            height: 90%;
        }
        span.address{
            font-size: small;
        }
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: white;
    margin-top: 2px;
    border: 1px solid black;
`

const ContentSubLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    padding: 20px;

    span.contentSL{
        font-size: 0.8rem;
    }

    button{
        text-align: left;
        background: none;
        border: none;
        text-decoration: underline;
        margin: 10px;
        font-size: 1.1rem;
        font-weight: 400;
    }

    @media screen and (max-width: 700px) {
        button{ 
            font-size: small;
        }    
    }  
    
`

const ContentSubRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 65%;
    height: 100%;
`

const ContentFooter = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    div{
        height: 100%;
        width: 60%;
        border: 1px solid black;
        display: flex;
        align-items: center;
        padding-left: 5px;
        &:nth-child(2){
            width: 39%;
            margin: 0 0 0 auto;
            img{
                height: 80%;
                margin: auto 5px;
            }
        }
    }
`

const ContentHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    img{
        width: 100%;
    }
    span.contentSLCH{
        font-size: 1.5rem;
        font-weight: bolder;
        margin: 10px 0;
    }

    &::after{
        content: '';
        position: absolute;
        bottom: -5px;
        height: 5px;
        width: 100%;
        background-color: #8B96F5;
    }
`

const Item = styled.button`
    position: relative;
    width: fit-content;
    margin: 10px 20px;
    pointer-events: all;
    cursor: pointer;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;

    figure{
        display: flex;
        flex-direction: column;
        img{
            width: 60px;
            height: 60px;
            margin: 5px 15px;
        }
        figcaption{
            color: black;
            white-space: nowrap;
            font-size: large;
        }
    }

    @media screen and (max-width: 700px) {
        margin: 5px 10px;
        figure{
            picture img{
            width: 40px;
            height: 40px;
            margin: 5px 15px;
            }
            figcaption{
            font-size: small;
            }
        }
    }
`

const DesktopItem = ({icon,title,func})=>(
    <Item onPointerDown={func}>
        <figure>
            <picture> <img src={icon} alt="title" /></picture>
            <figcaption>{title}</figcaption>
        </figure>
    </Item>
)

const FileExplorer = ({ activateTask, deactivateTask, id, pushZStack, appendToMinimize }) => {

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})
    const mainWindowRef = useRef()

    const [minimize,toggleMinimize] = useState(true)

     //offset works
     const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }

    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={pcIcon} alt="pc icon" /> My computer</span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button  onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem>Home</LinkItem>
                <LinkItem>Open</LinkItem>
                <LinkItem>File</LinkItem>
                <LinkItem>Settings</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <LinkItem className='back'> <img src={backIcon} alt="back icon"/> back</LinkItem>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <span className="subHeader">Address</span>
                <Address>
                    <img src={pcIcon} alt="pc icon"/>
                    <span className="address">My computer</span>
                </Address>
            </SubHeader>
            <Content>
                <ContentSubLeft>
                    <ContentHeader>
                        <img src={logoBanner} alt="b4rto banner" />
                        <span className="contentSLCH">My Computer</span>
                    </ContentHeader>
                    <span className="contentSL">Select an item to view its description.</span>
                    <button onPointerDown={()=>activateTask(4,1)}>Skills/Competencies</button>
                    <button onPointerDown={()=>activateTask(0,1)}>Portfolio</button>
                    <button onPointerDown={()=>activateTask(5,1)}>Gallery</button>
                </ContentSubLeft>
                <ContentSubRight>
                    <DesktopItem func={()=>activateTask(1,id)} title="Start Page" icon={logo}/>
                    <DesktopItem func={()=>activateTask(6,id)} title="Gallery" icon={picIcon}/>
                    <DesktopItem func={()=>activateTask(4,id)} title="Recycle Bin" icon={trashIcon}/>
                </ContentSubRight>
            </Content>
            <ContentFooter>
                <div><span>3 Object(s)</span></div>
                <div><img src={pcIcon} alt="icon"/> My Computer</div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default FileExplorer